import React from 'react'
import { useEffect, useState } from 'react'
import { connect, disconnect } from "starknetkit"
import {InjectedConnector} from "starknetkit/injected";
import {cairo, CallData, Contract, provider, WalletAccount, byteArray} from 'starknet';
import contract_abi from "./contract_abi.json";

function TokensPage({onSetWallet}) {
    const [connection, setConnection] = useState('');
    const [account, setAccount] = useState('');
    const [address, setAddress] = useState('');

    const [tokenPrice, setTokenPrice] = useState('');
    const [balance, setBalance] = useState('');

	const [tokenName, setTokenName] = useState('');
	const [tokenSymbol, setTokenSymbol] = useState('');

    const myFrontendProviderUrl = String(process.env.REACT_APP_STARKNET_RPC_URL);
	const defaultContractAddress = "0x047b1781647518199b3ddae10d06642026e44755b179815873f81c95c3624da8";

    useEffect(() => {
        const connectToStarknet = async() => {
            const { wallet } = await connect(
                {
                    modalMode: "neverAsk",
                    dappName: "FORCE PRIME HEROES",
                    connectors: [
                        new InjectedConnector({
                            options: {id: "argentX"}
                        }),
                        new InjectedConnector({
                            options: {id: "braavos"}
                        })
                    ]
                }
            )

            if (wallet && wallet.isConnected) {
                setConnection(wallet)
                setAccount(wallet.account)
                setAddress(wallet.selectedAddress)
            }
            await connectToStarknet().then((res) => {
                console.log(res)
            })
        }
    }, [])

    const connectWallet = async() => {
        const { wallet } = await connect(
            {
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )
		
        if(wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)

            const myWalletAccount = new WalletAccount({ nodeUrl: myFrontendProviderUrl }, wallet);

            //const bl = await myWalletAccount.getBlockNumber();
            //console.log(bl)
			
			const contractAddress = document.getElementById('tokenContract')?.value || defaultContractAddress;

            const tokenContract = new Contract(contract_abi, contractAddress, myWalletAccount)

            const price = await tokenContract.get_current_price();
            console.log("---------")
            console.log(Number(price))
            setTokenPrice(Number(price) / 1e18)

            const balance = await tokenContract.balance_of(wallet.selectedAddress);
            console.log("---------")
            console.log(balance)
            setBalance(Number(balance))

        }
    }

    const disconnectWallet = async() => {
        await disconnect()
        setConnection(undefined)
        setAccount(undefined)
        setAddress('')
    }
	
	const tokenInitializeBtn = async() => {
        const {wallet} = await connect(
            {
                modalMode: "neverAsk",
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )

        if (wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)
			
			const contractAddress = document.getElementById('tokenContract').value || defaultContractAddress;

            const myWalletAccount = new WalletAccount({nodeUrl: myFrontendProviderUrl}, wallet);
            const tokenContract = new Contract(contract_abi, contractAddress, myWalletAccount);

			const name = document.getElementById('tokenName').value;
			const symbol = document.getElementById('tokenSymbol').value;
			console.log(name + " " + symbol);

            const call = await myWalletAccount.execute([
                {
                    contractAddress: contractAddress,
					entrypoint: 'initializer',
					calldata: CallData.compile({
						name: byteArray.byteArrayFromString(name),
						symbol: byteArray.byteArrayFromString(symbol),
						initial_supply: cairo.uint256(0),
						recipient: contractAddress
					})
                }
            ]).then( async () => {
                await provider.waitForTransaction(call.transaction_hash)
                    .catch(e => {
                        console.log(e)
                    });
            }).catch( e => {
                console.log(e)
            });
        }
    }

    const tokenBuyBtn = async() => {
        const {wallet} = await connect(
            {
                modalMode: "neverAsk",
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )

		
        if (wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)

			const contractAddress = document.getElementById('tokenContract').value || defaultContractAddress;
		
            const myWalletAccount = new WalletAccount({nodeUrl: myFrontendProviderUrl}, wallet);

            const bl = await myWalletAccount.getBlockNumber();
            console.log(bl)

            const tokenContract = new Contract(contract_abi, contractAddress, myWalletAccount)

            const price = await tokenContract.get_current_price();
            console.log(price);
            setTokenPrice(Number(price) / 1e18);

			const balance = await tokenContract.balance_of(wallet.selectedAddress);
            setBalance(Number(balance));

            const multiCall = await myWalletAccount.execute([
                {
                    contractAddress: "0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d",
                    entrypoint: 'approve',
                    calldata: CallData.compile({
                        spender: contractAddress,
                        amount: cairo.uint256((price)),
                    }),
                },
                {
                    contractAddress: contractAddress,
                    entrypoint: 'purchase',
                    calldata: CallData.compile({
                        amount: 1,
                        max_price: cairo.uint256((price))
                    }),
                },
            ]).then( async () => {
                await provider.waitForTransaction(multiCall.transaction_hash)
                    .catch(e => {
                        console.log(e)
                    });
            }).catch( e => {
                console.log(e)
            });
        }
    }

    return (
        <div className={"d-flex align-items-center flex-column"}>
            {
                !connection ?
                    <button className={"fp-btn-bg"} onClick={connectWallet}>Connect Wallet</button>
                    :
                    <>
                        <button className={"fp-btn-bg"}
                                onDoubleClick={disconnectWallet}>{address.slice(0, 5)}...{address.slice(60, 66)}</button>
                        <button className={"fp-btn-bg"} onClick={tokenBuyBtn}>Buy Token</button>
						<button className={"fp-btn-bg"} onClick={tokenInitializeBtn}>Initialize Token</button>
                        <p>Token price: {tokenPrice}</p>
                        <p>Current tokens: {balance}</p>
						
						<input autoFocus type="text" id="tokenContract" placeholder="Contract Address"
							pattern="[A-Za-z0-9\-]+"/> 
						
						<input autoFocus type="text" id="tokenName" placeholder="Token Name"
							maxLength="30"
							minLength="5"
							pattern="[A-Za-z0-9\-]+"/> 
							 
						<input autoFocus type="text" id="tokenSymbol" placeholder="Token Symbol"
							maxLength="30"
							minLength="2"
							pattern="[A-Za-z0-9\-]+"/> 
                    </>


            }


        </div>
    )
}

export default TokensPage