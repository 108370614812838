import React, {Component} from "react";
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";

class MapsTabsElement extends Component{
    constructor(props) {
        super(props);

        this.state = {

        }

    }

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div className="d-flex flex-row  justify-content-evenly mb-3 pt-5 flex-wrap">

                    <div className={"card game-view-card mt-1"}>
                        <div className={"card-body"}>
                            <HashLink to={"/#maps-campaign1"}><img className={"img-fluid game-image rounded-3"}
                                                                   src={require("./static/btn-campaign-2.jpg")}
                                                                   alt="Force Prime Heroes"/>
                            </HashLink>
                            <HashLink to={"/#maps-campaign1"}
                                      className="fp-btn-bg  mt-2">Campaign</HashLink>
                        </div>
                    </div>


                    <div className={"card game-view-card mt-1"}>
                        <div className={"card-body"}>
                            <HashLink to={"/#maps-season"}><img className={"img-fluid game-image rounded-3"}
                                                                src={require("./static/btn-season-2.jpg")}
                                                                alt="Force Prime Heroes"/>
                            </HashLink>
                            <HashLink to={"/#maps-season"}
                                      className="fp-btn-bg mt-2">Tournament</HashLink>
                        </div>
                    </div>

                    <div className={"card game-view-card mt-1"}>
                        <div className={"card-body"}>
                            <Link to={"/hero"}><img className={"img-fluid game-image rounded-3"}
                                                                src={require("./static/btn-mint-heroes.jpg")}
                                                                alt="Force Prime Heroes"/>
                            </Link>
                            <Link to={"/hero"}
                                      className="fp-btn-bg mt-2">Mint Cards</Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default MapsTabsElement;
