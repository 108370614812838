import { connect, disconnect } from "starknetkit"
import {Component} from "react";
import { ec, stark, hash, CallData } from "starknet";
import {authGetDummyUserData} from "./api/requests";
import {AccountInfoManager} from "./accountInfoManager";
import {toast} from "react-toastify";
import {Route, Routes} from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import MainPage from "./MainPage";
import CommonLayout from "./CommonLayout";
import {delay} from "./common/utility/delay";
import { ArgentMobileConnector } from "starknetkit/argentMobile";
import MapPage from "./Map";
import ElementWrapper from "./Wrapper";
import {Page404} from "./Page404";
import MapTopPage from "./MapTopPage";
import SeasonTopPage from "./SeasonTopPage";
import TokensPage from "./TokensPage";
import HeroesTopPage from "./HeroesTopPage";

class App extends Component {
    constructor(props) {
        super(props);

        window.alerts = {
            success: (text) => this.notifySuccess(text),
            alert: (text) => this.notifyAlert(text),
            loading: (text) => this.loadingInProgress(text),
            loadingStop: (id, text) => this.loadingInProgressStop(id, text),
            loadingStopFast: (id, text) => this.loadingInProgressStopFast(id, text),
            loadingStopSuperFast: (id, text) => this.loadingInProgressStopSuperFast(id, text),

        }

        this.state = {
            authToken: null,
            account: null,
            isLoading: false,
            gameStarknetData: {},
            isAnonymous: false,
        };

        this.onClickWallet = this.onClickWallet.bind(this);
        this.disconnectWallet = this.disconnectWallet.bind(this);
        this.onClickAnonymous = this.onClickAnonymous.bind(this);
        this.loadFromServer = this.loadFromServer.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onSetWallet = this.onSetWallet.bind(this);

    }

    async componentDidMount() {
        const connection = await connect( { modalMode: "neverAsk", dappName: "FORCE PRIME HEROES" } ).catch(e => {
            console.log(e)
        }).catch(e => {
            console.log(e)
        })

        const wallet = localStorage.getItem('wallet');

        if (wallet) {
            console.log("Wallet connected")
            console.log(wallet)
            await this.loadFromServer(wallet)
        } else {
            const anonymousId = localStorage.getItem('anonymous');
            if (anonymousId !== null) {
                await this.loadFromServer(anonymousId)
            }
        }
    }

    async notifyAlert(text) {
        toast.error(text, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async notifySuccess(text) {
        toast.success(text, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    loadingInProgress(text) {
        return toast.loading(text, {
            position: "top-center",
            hideProgressBar: false,
            autoClose: 2500,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    async loadingInProgressStop(id, text) {
        toast.update(id, { render: text, type: "success", isLoading: false, autoClose: 2500, });
    }

    async loadingInProgressStopFast(id, text) {
        toast.update(id, { render: text, type: "warning", isLoading: false, autoClose: 500, });
    }

    async loadingInProgressStopSuperFast(id, text) {
        toast.update(id, { render: text, type: "success", isLoading: false, autoClose: 100, });
    }

    async loadFromServer(userId) {
        //this.setState({loading: true})
        await authGetDummyUserData({
            user_id: userId,
        }).then(async (response) => {

            this.setState({
                authToken: response.auth_token,
                account: userId,
                gameStarknetData: {
                    sid: response.sid,
                    user_id: response.user_id,
                    wallet: response.wallet,
                    starknetConfig: response.starknet_config
                },
                loading: false
            })
            localStorage.setItem('authToken', response.auth_token);
            localStorage.setItem('userId', response.user_id);
            await AccountInfoManager.retrieveUserDataPromise();

            console.log(this.state.gameStarknetData);

        }).catch((e) => {
            console.log("Internal Server Error. Please refresh the page and try again");
            this.notifyAlert("Internal Server Error. Please refresh the page and try again");
            localStorage.clear();
            this.setState({
                account: null,
                loading: false,
            })
        })
    }

    async onClickWallet() {
        console.log("n/a")
    }

    async onSetWallet(wallet) {
        const id = this.loadingInProgress("Fetching user data...");

        await this.loadFromServer(wallet).then(
            async () => {
                await this.notifySuccess("Wallet connected successfully!");
                localStorage.setItem('wallet', wallet);
                await delay(500);
                window.location.reload();
                //this.loadingInProgressStop(id, "Done...")
            }
        )
    }

    async onClickAnonymous() {

        // Generate public and private key pair.
        const privateKey = stark.randomAddress();
        console.log('New OZ account:\nprivateKey=', privateKey);
        const starkKeyPub = ec.starkCurve.getStarkKey(privateKey);
        console.log('publicKey=', starkKeyPub);

        const OZaccountClassHash = "0x04d07e40e93398ed3c76981e72dd1fd22557a78ce36c0515f679e27f0bb5bc5f";
        // Calculate future address of the account
        const OZaccountConstructorCallData = CallData.compile({ publicKey: starkKeyPub });
        const OZcontractAddress = hash.calculateContractAddressFromHash(
            starkKeyPub,
            OZaccountClassHash,
            OZaccountConstructorCallData,
            0
        );
        console.log('Precalculated account address=', OZcontractAddress);
        // this.setState({
        //     account: OZcontractAddress,
        // })
        localStorage.setItem('anonymous', OZcontractAddress);
        const id = this.loadingInProgress("Fetching user data...");
        await this.loadFromServer(OZcontractAddress).then(
            async () => {
                await this.notifySuccess("Wallet connected successfully!");
                await this.loadingInProgressStop(id, "User data fetched successfully")
                await delay(500);
                window.location.reload();
            }
        )

    }

    async disconnectWallet() {

        const anonymousId = localStorage.getItem('anonymous');
        if (anonymousId !== null) {
            console.log("Wallet disconnected 2");
            localStorage.clear();
            this.notifySuccess("Wallet disconnected");
            await delay(500);

            window.location.reload();
        } else {
            await disconnect().then(
                async () => {
                    console.log("Wallet disconnected 1");
                    localStorage.clear();
                    await this.notifySuccess("Wallet disconnected");
                    await delay(500);
                    window.location.reload();
                }
            ).catch((e) => {
                    localStorage.clear();
                    console.log("Wallet disconnect failed", e);
                }
            );
        }


    }
    render() {
        return (
            <>
                <Routes>
                    <Route path="/" element={<CommonLayout  onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet} onSetWallet={this.onSetWallet} />}>
                        <Route path="/"  element={<MainPage gameStarknetData={this.state.gameStarknetData}  onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet}  onSetWallet={this.onSetWallet} />}/>
                        <Route path="/leaderboard" element={<ElementWrapper routeElement={SeasonTopPage} gameStarknetData={this.state.gameStarknetData} onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet}  onSetWallet={this.onSetWallet} />}  />
                        <Route path="/hero" element={<ElementWrapper routeElement={HeroesTopPage} gameStarknetData={this.state.gameStarknetData} onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet}  onSetWallet={this.onSetWallet} />}  />
                        <Route path="/play"  element={<ElementWrapper routeElement={MapPage} gameStarknetData={this.state.gameStarknetData}  onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet}  onSetWallet={this.onSetWallet} />} />
                        <Route path="/map/top"  element={<ElementWrapper routeElement={MapTopPage} gameStarknetData={this.state.gameStarknetData}  onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet}  onSetWallet={this.onSetWallet} />}  />
                        <Route path="/token"  element={<ElementWrapper routeElement={TokensPage} gameStarknetData={this.state.gameStarknetData}  onClickWallet={this.onClickWallet} onClickAnonymous={this.onClickAnonymous} account={this.state.account} disconnectWallet={this.disconnectWallet}  onSetWallet={this.onSetWallet} />} />
                        <Route path="*" element={<Page404 />} />
                    </Route>
                </Routes>
            </>
        );
    }
}

export default App;
