import React, {Component} from "react";
import {Loading} from "./common/utility/loading";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import {apiGetMapConfig} from "./api/requests";
import {ModalAuthLite} from "./ModalAuth";
import ModalHeroChange from "./ModalHeroChange";
import MapHeroChange from "./MapHeroChange";

class MapPage extends Component{
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');

        this.state = {
            mapId : this.props.search.get("map"),
            loading: true,
            accessToken: token,
            hero: null,
            newGameRef: React.createRef()
        }

        console.log("Game props", this.props)
        //const mapId = this.props.match.params.map;
        //console.log("map mapId", this.props.search.get("map"))

        this.loadMapConfig = this.loadMapConfig.bind(this);
        this.handleEventFromGame = this.handleEventFromGame.bind(this);

    }

    componentDidMount() {
        window.addEventListener("message", this.handleEventFromGame)
        this.loadMapConfig()
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleEventFromGame)
    }

    async loadMapConfig() {
        if(this.state.accessToken === null) {
            this.setState({
                loading: false,
            })
            return
        }

        let i = 0;
        while (true) {
            let id
            if( i > 0) {
                id = window.alerts.loading("Please wait, loading map information...("+i+")");
            }
            let res = await AccountInfoManager.mapConfig(this.state.mapId)
            if (res.res) {
                if( i > 0) {
                    window.alerts.loadingStopSuperFast(id, "Done");
                }
                break
            }
            else {
                if (res.error === 9205 || res.error === 9207 || res.error === 9209) {
                    if( i > 0) {
                        window.alerts.loadingStopFast(id, "Please wait a little longer.");
                        await delay(1000)
                    }
                } else {
                    window.alerts.loadingStopFast(id, "Internal Server Error. Please refresh the page and try again.");
                    window.alerts.alert("Internal Server Error. Please refresh the page and try again.");
                    break
                }
            }
            i++;
        }

        await apiGetMapConfig(
            this.state.accessToken, this.state.mapId
        ).then(async (response) => {
            await delay(1000);
            this.setState({
                mapSettings: response.map_config,
                mapStatic: response.map_static,
                loading: false,
                hero: response.hero,
            })
        }).catch(e => {
            window.alerts.alert("Internal Server Error. Please refresh the page and try again.")
        })
    }

    handleEventFromGame = async (event) => {
        console.log("e:", event);
        if (event.data.type === "game-result") {
            const score = event.data.score;
            const days = event.data.days;

            let mapName= "MAP";

            if(this.state.mapStatic !==undefined && this.state.mapStatic !==null) {
                mapName = this.state.mapStatic.config.name;
            }
            console.log(mapName);

            const text = encodeURIComponent("I've just completed an adventure in the fully on-chain game @ForcePrime_io and here are my results:\nMap: "+mapName+"\nDays: "+days+"\nScore: " + score + "\n\nJoin the tournament at https://forceprime.io");
            const url = encodeURI("https://forceprime.io");
            const hashtags = encodeURI("forceprime,focg,starknet,game,heroes");

            window.open('https://twitter.com/share?text='+text, '_blank', 'noreferrer');
        }

        if (event.data.type === "new-game") {
            this.state.newGameRef.current.focus();
            let i = 0;
            while (true) {
                const id = window.alerts.loading("Saving your result. Don't reload the page...("+i+")");
                i++;
                let res = await AccountInfoManager.mapReset(this.state.mapId);
                if (res.res) {
                    window.alerts.loadingStop(id, "You game progress has been reset successfully");
                    await delay(500)
                    window.location.reload()
                    break
                }
                else {
                    if (res.error === 9605) {
                        window.alerts.loadingStopFast(id, "Saving your result. Don't reload the page. Please wait a little longer.");
                        await delay(1000)
                    } else {
                        window.alerts.loadingStopFast(id, "Internal Server Error. Please refresh the page and try again.");
                        window.alerts.alert("Internal Server Error. Please refresh the page and try again.");
                        break
                    }
                }
            }

        }

        if (event.data.type === "restart") {
            window.alerts.loading("Reloading page. Please wait...");
            await delay(500)
            window.location.reload()

        }
    }

    //http://twitter.com/share?text=text goes here&url=http://url goes here&hashtags=hashtag1,hashtag2,hashtag3

    render() {
        if (this.state.loading === true || this.state.mapSettings === null) {
            return <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <h2 className={"notransform-h1 text-center"}>Force Prime Heroes</h2>
                </div>
                <Loading/>
                </>
        }

        if(this.props.account === null || this.state.accessToken === null){
            return <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <h2 className={"notransform-h1 text-center"}>Force Prime Heroes</h2>
                </div>
                <ModalAuthLite label={"Play"} onClickWallet={this.props.onClickWallet}
                               onClickAnonymous={this.props.onClickAnonymous} onSetWallet={this.props.onSetWallet} />
            </>
        }

        let mapName= "MAP";

        if(this.state.mapStatic !==undefined && this.state.mapStatic !==null) {
            mapName = this.state.mapStatic.config.name;
        }

        if(this.state.hero === null) {
            return <>
                <div className={"pt-4  align-content-center text-light d-flex flex-column"}>
                    <h2 className={"notransform-h2 text-center"}>{mapName}</h2>
                </div>
                <MapHeroChange currentHero={0} mapId={this.state.mapId}/>

            </>
        }

        let iframeUrl = this.state.mapSettings.webgl_url +
            "?private_key=" + this.props.gameStarknetData.wallet.privateKey +
            "&public_key=" + this.props.gameStarknetData.wallet.publicKey +
            "&account=" + this.props.gameStarknetData.wallet.address +
            "&access_token=" + String(process.env.REACT_APP_PUBLIC_KEY) +
            "&cc_sid=" + this.props.gameStarknetData.sid +
            "&cc_uid=" + this.props.gameStarknetData.user_id +
            "&sid=" + this.props.gameStarknetData.sid +
            "&uid=" + this.props.gameStarknetData.user_id +
            "&world_id=" + this.state.mapSettings.world +
            "&server=" + String(process.env.REACT_APP_API_URL) + "" +
            "&torii=" + this.state.mapSettings.torii + "/dojo/" +
            "&contract=" + this.state.mapSettings.contract+
            "&cc_user_token="+this.state.accessToken;

        return (
            <>

                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light pb-2"}>
                    <h2 className={"notransform-h2 text-center"}>{mapName}<ModalHeroChange label={"Switch Hero"}
                                                                                           currentHero={this.state.hero}
                                                                                           mapId={this.state.mapId}/></h2>
                </div>
                <div ref={this.state.newGameRef} tabIndex={-1}></div>
                <div className={"game-iframe"}>
                    <iframe
                        className={"w-100 h-100"}
                        src={iframeUrl}
                        name="game"
                        id="game_iframe_bg"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen={true}
                        allow="autoplay;"
                        frameBorder="0"
                        scrolling="no"
                    >
                    </iframe>
                </div>

            </>
        );
    }

}

export default MapPage;
