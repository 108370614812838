import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroData, apiGetHeroUserData,
} from "./api/requests";
import {LoadingInline} from "./common/utility/loading";
import HeroMintButton from "./HeroMintButton";
import HeroBuyShardByGemButton from "./HeroBuyShardByGemButton";

class HeroTopElement extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');


        this.state = {
            loadingFromChain: true,
            loadingFromServer: true,
            loadingFromServerUserData: true,
            authToken: token,
            cardsMinted: 0,
        }

        this.loadCardDataFromServer = this.loadCardDataFromServer.bind(this);
        this.loadUserCardDataFromServer = this.loadUserCardDataFromServer.bind(this);
        this.loadCardDataFromChain = this.loadCardDataFromChain.bind(this);

        this.potentialPrize = this.potentialPrize.bind(this);
        this.cardsMinted = this.cardsMinted.bind(this);
        this.potentialCardPrize = this.potentialCardPrize.bind(this);
        this.currentCardPrice = this.currentCardPrice.bind(this);
        this.mintButton = this.mintButton.bind(this);
        this.userHeroShards = this.userHeroShards.bind(this);


    }

    componentDidMount() {
        this.loadCardDataFromServer()
        this.loadCardDataFromChain()
        this.loadUserCardDataFromServer()
    }

    async loadCardDataFromChain() {
        this.setState({
            loadingFromChain: false,
        })
    }

    async loadCardDataFromServer() {

        await apiGetHeroData(
            this.props.heroSeason.uid
        ).then(async (response) => {
            if (response.hero !== undefined && response.hero !== null) {

                /*
                {
    "count": 1,
    "current_price": 0.987945,
    "error": false,
    "hero": {
        "guid": "149dadfa9b3e1490683a136c11a6ff6b",
        "id": 259,
        "file_name": "Hero-3-Elven",
        "hero_name": "Estel",
        "hero_description": "Elven Clan Leader\nAtk: 3, Def: 1, Spd: 1, Wlth: 0",
        "playable": "true",
        "image_url": "https://gc-cdn.forceprime.io/forceprime/icons/hero/Elven.png",
        "token_contract": "0x682c6c6f83db588e1ef4469164e481c4853642021485e695392cc7469dcf290",
        "world": "0x493d97f6f116361e61db6b63c08d3b87d771100456dac9d2f5cdbb6ec1b15ca"
    },
    "hero_id": "259",
    "success": true,
    "t": 1725437042,
    "total_balance": "2.2220",
    "total_spent": "1"
}
                 */
                this.setState({
                    heroData: {
                        tokenContract: response.hero.token_contract,
                        cardPrice: response.current_price,
                        cardsMinted: response.count,
                    },
                    loadingFromServer: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {

                }
            })
            this.setState({
                loadingFromServer: true,
            })
        })


    }

    async loadUserCardDataFromServer() {
        if(this.state.authToken === null) {
            this.setState({
                loadingFromServerUserData: true,
                userCardsMinted: 0,
            })
            return
        }
        await apiGetHeroUserData(
            this.state.authToken,
            this.props.heroSeason.uid
        ).then(async (response) => {
            if (response.token_amount !== undefined && response.token_amount !== null) {

                this.setState({
                    userCardsMinted: response.token_amount,
                    loadingFromServerUserData: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {
                    userCardsMinted: 0,
                }
            })
            this.setState({
                loadingFromServerUserData: true,
            })
        })


    }

    potentialPrize() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {parseFloat((this.props.prizePool * this.props.deductionPrizePool).toFixed(2))} <span className={"main-color-important"}>STRK</span>
        </>
    }

    cardsMinted() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {this.state.heroData.cardsMinted}
        </>
    }

    potentialCardPrize() {
        if(this.state.loadingFromServer === true || this.state.loadingFromChain === true) {
            return <LoadingInline />
        }
        return <>
            {parseFloat((this.props.prizePool * this.props.deductionPrizePool / this.state.heroData.cardsMinted).toFixed(2))} <span className={"main-color-important"}>STRK</span>
        </>
    }

    currentCardPrice() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {this.state.heroData.cardPrice} <span className={"main-color-important"}> STRK</span>
        </>
    }

    mintButton() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        //{this.state.heroData.tokenContract}
        console.log(this.state.heroData.tokenContract)
        return <>
            <HeroMintButton
                contractAddress={this.state.heroData.tokenContract}
            />
        </>
    }

    userHeroShards() {
        if(this.state.authToken === null) {
            return <></>
        }
        if(this.state.loadingFromServer === true || this.state.loadingFromServerUserData === true) {
            return <LoadingInline />
        }
        return <>
            <p className={"mb-0"}><span
                className={"main-color-important"}>My cards:</span> {this.state.userCardsMinted}</p>
            <p className={"mb-0"}><span
                className={"main-color-important"}>My pot.prize:</span> {parseFloat((this.state.userCardsMinted * this.props.prizePool * this.props.deductionPrizePool / this.state.heroData.cardsMinted).toFixed(2))} <span className={"main-color-important"}>STRK</span></p>


        </>
    }

    render() {
        return <div key={this.props.heroSeason.uid}
                    className="mb-3 d-flex flex-row hero-element">
            <div
                className={"d-flex flex-column align-content-center me-3 leaderboard-table-place"}>
                <div><span>{this.props.heroSeason.place}</span></div>
                    </div>
                    <div className={"d-flex flex-column align-content-center me-3"}>
                        <img src={this.props.heroSeason.avatar}
                             className="hero-element-image rounded-5" alt={this.props.heroSeason.username}/>
                    </div>
                <div className="text-white choose-hero-card-body pe-3">
                    <h5 className="main-color-important">{this.props.heroSeason.username}</h5>
                    <p className={"mb-0"}><span
                        className={"main-color-important"}>Season points:</span> {this.props.heroSeason.points}</p>
                    <p className={"mb-0"}><span
                        className={"main-color-important"}>Potential prize:</span> {this.potentialPrize()}</p>
                    <p className={"mb-0"}><span
                        className={"main-color-important"}>Cards minted:</span> {this.cardsMinted()}</p>
                    {this.userHeroShards()}
                </div>

                <div className="text-white choose-hero-card-body">
                    <h5 className="main-color-important">&nbsp;</h5>
                    <p className={"mb-0"}><span
                            className={"main-color-important"}>Card price now:</span> {this.currentCardPrice()}
                        </p>
                        <p className={"mb-0"}><span className={"main-color-important"}>Pot.prize/card:</span> {this.potentialCardPrize()}
                        </p>
                    </div>
                    <div className={"ms-auto d-flex flex-row  align-items-start "}>
                        {this.mintButton()}
                    </div>
                </div>

    }
}

export default HeroTopElement;
