import React, {Component} from "react";
import {Loading} from "./common/utility/loading";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import {apiGetSeasonPass} from "./api/requests";
import SeasonPassOneLabel from "./SeasonPassOneLabel";

class SeasonPassPanel extends Component{
    constructor(props) {
        super(props);
        const token = localStorage.getItem('authToken');
        this.state = {
            authToken: token,
            loading: true,
            pass_data: null,
        }
        this.loadSeasonPass = this.loadSeasonPass.bind(this);
        this.onClaimReward = this.onClaimReward.bind(this);
    }

    async componentDidMount() {
        if (this.props.account === null || this.state.authToken === null) {

        } else {
            await this.loadSeasonPass()
        }
    }

    componentWillUnmount() {
    }

    async onClaimReward(claimId) {
        const id = window.alerts.loading("Claiming reward. Please wait...");

        let res

        await AccountInfoManager.claimSeasonPass(this.props.grandSeason.config.grand_season_id,claimId).then(async (response) => {
           console.log("claim response", response)

           if(response.error !== undefined && response.error !== false) {
               window.alerts.loadingStopFast(id, "Claiming reward: internal error");
           } else {
               await AccountInfoManager.refresh();
               window.alerts.loadingStop(id, "Claiming reward. Done!");
           }
           res = response
        }).catch(e => {
           window.alerts.alert("Claiming reward: internal error")
        })

        return res
    }

    async loadSeasonPass() {
        await apiGetSeasonPass(this.state.authToken, this.props.grandSeason.config.grand_season_id).then(async (response) => {
            await delay(1000);
            this.setState({
                loading: false,
                pass_data: response.pass_data
            })
        }).catch(e => {
            window.alerts.alert("internal error")
        })
    }

    render() {

        const blockHeader = <>
            <div className="pt-4 panel-row width100" style={{justifyContent: "space-between"}}>
                <h1 className={"notransform-h1"}>Season Progress</h1>
            </div>
            <p>Welcome to Force Prime Heroes, a fully on-chain game inspired by the Heroes of Might and Magic series. In
                this game, you'll explore the map with your hero, expand your army, defeat enemies, and take down the
                Bone Dragon or other map's bosses.</p>

        </>

        if (this.props.account === null || this.props.accessToken === null) {
            return <>
                {blockHeader}
                <div className={"block-panel-main game-info-right-part "}>
                    <div className={"card-body"}>
                    <p>Earn Season Points by playing on tournament maps to claim more rewards.</p>
                        <div className={"d-flex align-items-start justify-content-evenly flex-wrap"}>
                            {this.props.grandSeason.config.season_pass.map((v, k) => {
                                    if( k === 0 ) {
                                        return <SeasonPassOneLabel key={v.claim_id} login={true} claimed={false} success={true}  currentPoints={0}
                                                                   points={v.score} label={v.claim_text} claimId={v.claim_id}
                                                                   value={100} onClickWallet={this.props.onClickWallet} onSetWallet={this.props.onSetWallet}
                                                                   onClickAnonymous={this.props.onClickAnonymous} onClaimReward={() => {}}/>
                                    }
                                    return <SeasonPassOneLabel key={v.claim_id} currentPoints={0} points={v.score} label={v.claim_text}
                                                               value={0} claimId={v.claim_id}/>
                            })}

                        </div>
                    </div>
                </div>
            </>
        }

        if(this.state.loading === true) {
            return <>
                {blockHeader}
                <div className={"block-panel-main game-info-right-part "}>
                    <div className={"card-body"}>
                        <p>Earn Season Points by playing on tournament maps to claim more rewards.</p>
                        <div className={"d-flex align-items-start justify-content-evenly flex-wrap"}>
                           <Loading />
                        </div>
                    </div>
                </div>
            </>
        }

        return (
            <>
                {blockHeader}
                <div className={"block-panel-main game-info-right-part "}>
                    <div className={"card-body"}>
                        <p>Earn Season Points by playing on tournament maps to claim more rewards.</p>
                        <div className={"d-flex align-items-start justify-content-evenly flex-wrap"}>
                            {this.state.pass_data.map((v, k) => {
                                return <SeasonPassOneLabel key={v.claim_id} claimed={v.claimed} success={v.success}  currentPoints={v.current_points}
                                               points={v.score} label={v.label} claimId={v.claim_id}
                                               value={v.value}  onClaimReward={this.onClaimReward}/>

                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default SeasonPassPanel;
