import Modal from 'react-bootstrap/Modal';
import {useState} from "react";
import Connect from "./connect";

function ModalAuth({label, onClickWallet, onClickAnonymous, isSmall = false, onSetWallet}) {
    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClickWalletBtn = (data, e) => { setShow(false); onClickWallet(); };
    const onClickAnonBtn = (errors, e) => { setShow(false); onClickAnonymous(); };

    return (
        <>
            <a className={(isSmall === true) ? "fp-btn-bg-small" : "fp-btn-bg" } onClick={handleShow}>
                {label}
            </a>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">
                        <h1 className={"notransform-h1 text-center"}>Connect</h1>
                            <div className={"p-5 d-flex flex-column align-items-center"}>
                                <Connect onSetWallet={onSetWallet} />
                                <button className={"m-1 fp-btn-bg w-100"} onClick={onClickAnonBtn}>Anonymous Login</button>
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export function ModalAuthLite({label, onClickWallet, onClickAnonymous, onSetWallet}) {
    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClickWalletBtn = (data, e) => { setShow(false); onClickWallet(); };
    const onClickAnonBtn = (errors, e) => { setShow(false); onClickAnonymous(); };

    return (
        <>
            <div className="d-flex flex-column align-items-center">
                <h2 className={"notransform-h2 text-center"}>Connect</h2>
                <p  className={"text-center"}>To play the game, please connect your wallet or create an anonymous login.</p>
                <div className={"p-1 d-flex flex-column align-items-center"}>
                    <Connect onSetWallet={onSetWallet}/>
                    <button className={"m-1 fp-btn-bg w-100"} onClick={onClickAnonBtn}>Anonymous Login</button>
                </div>
            </div>
        </>
    );
}

export default ModalAuth;
